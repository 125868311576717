import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonial from './components/testimonial/Testimonial'
import Team from './components/team/Team'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Dhriti from './components/dhriti/Dhriti'
import './index.css'


const App = () => {
  return (
   <>
   <Header/>
   <Nav/>
   <About/>
   <Services/>
   <Portfolio/>
   {/* <Dhriti/> */}
   <Experience/>
   {/* <Team/> */}
   <Testimonial/>
   <Contact/>
   <Footer/>
  
   
   </>
  )
}

export default App
