import React from 'react'
import './testimonial.css'
import AVTR1 from '../../assets/man2.webp'
import AVTR2 from '../../assets/man3.png'
import AVTR3 from '../../assets/women1.png'
import AVTR4 from '../../assets/women3.png'

// import Swiper core and required modules
import {Pagination} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data=
[

    {
       avatar:AVTR1,
       name: 'Kumar Sanjay',
       review: 'Dhriti delivered an exceptional website for our business. Her professionalism, attention to detail, and timely delivery made her a top choice for web development.'
     },
     
     {
        avatar:AVTR2,
       name: 'Ashish Sinha',
       review: 'Dhriti is a reliable partner for web development projects. Her innovative approach and expertise in cutting-edge technologies consistently result in outstanding web solutions'
     },


     {
        avatar:AVTR3,
       name: 'Shailja Sahay',
       review: 'Dhriti is my go-to choice for Java software development. Her agile approach ensure efficient and high-quality Java applications.'
     },

     {
        avatar:AVTR4,
       name: 'Avni',
       review: 'Innovations is a game-changer in UI/UX. Her creative approach and user-centric designs have significantly improved our products usability and appeal.'
     }
]
const Testimonial = () => {
  return (
    <section id='testimonial'>
     <h5>Review from Clients</h5>
     <h2>Testimonials</h2>
     <Swiper className="container testimonials_container" modules={[Pagination]} spaceBetween={40} slidesPerView={1} pagination={{ clickable: true }}>
     {
       data.map(({avatar,name,review},index)=>{
        return(
          <SwiperSlide key={index} className='testimonial'>
          <div className="client_avatar">
            <img src={avatar} alt="Avatar One" />
            
          </div>
          <h5 className='client_name'>{name}</h5>
            <small className='client-review'>{review}</small>
  
        </SwiperSlide>
        )
       })

     }
     
     </Swiper>
    </section>
  )
}

export default Testimonial
