import React from 'react'
import './about.css'
import ME from '../../assets/me.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import ME1 from '../../assets/lo.png'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image" style={{height:"300px"}}>
            <img src={ME1} alt="me-about" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <FaAward className='about_icon'/>
                <h5>Experience</h5>
                <small>1+ Years Working</small>
            </article>

            <article className='about_card'>
              <FiUsers className='about_icon'/>
                <h5>Clients</h5>
                <small>10+ Worldwide</small>
            </article>

            <article className='about_card'>
              <VscFolderLibrary className='about_icon'/>
                <h5>Projects</h5>
                <small>20+ Completed</small>
            </article>
          </div>
          <h5 style={{textAlign:'justify',color:'grey',lineHeight:2,marginBottom:'3px',marginTop:'4px'}}>

"We are a dynamic startup company at the forefront of digital innovation. Our core offerings encompass UI/UX design, full-stack web development, and software development services. Committed to excellence, our passionate team merges creativity with cutting-edge technology to produce captivating user experiences, responsive web applications, and customized software solutions. We are driven by a vision to transform your digital aspirations into reality, helping startups and businesses thrive in the ever-evolving digital landscape. With a focus on client satisfaction and forward-thinking solutions, we are your trusted partner on the journey to digital success."</h5>
        <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
