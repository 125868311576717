import React, { useState } from 'react';
import './portfolio.css';
import IMG1 from '../../assets/portfolio1.jpg';
import IMG2 from '../../assets/portfolio2.webp';
import IMG3 from '../../assets/portfolio3.webp';
import IMG4 from '../../assets/portfolio4.jpg';
import IMG5 from '../../assets/portfolio5.jpg';
import IMG6 from '../../assets/portfolio6.jpg';

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'GEET',
    link: 'https://github.com/dhriti1810/geet',
    demo: 'GitHub',
    details: `The GEET is a music player which is :

User-friendly graphical interface with playback controls (play, pause, stop, volume adjustment).
Versatility in supporting various audio formats (MP3, WAV, FLAC) using Pygame's mixer module.
Effective playlist management, allowing users to create, load, and manage playlists.
Display of track information (title, artist, duration) via audio file metadata.
Seek bar for precise navigation within songs.
Customization options for themes and robust error handling.
Cross-platform compatibility (Windows, macOS, Linux) and event handling.
Playlist saving and the potential for integration with online music services or APIs to enhance functionality.`,
  },
  {
    id: 2,
    image: IMG2,
    title: "Kehdo",
    link: "https://kehdo.life/",
    demo: "View Site",
    details: "KEHDO is a compassionate online platform dedicated to mental health and well-being. Our mission is to raise awareness about mental health issues and provide a convenient way to book appointments with top-notch mental health professionals. At KEHDO, we understand the importance of mental well-being and offer a safe space for individuals to seek help and support. Our team of experienced and empathetic counselors and therapists is here to guide you through life's challenges. Whether you're dealing with stress, anxiety, depression, or simply need someone to talk to, KEHDO is your partner on the path to mental wellness. Take the first step towards a healthier mind and brighter future – book an appointment with us today. Your mental health matters, and at KEHDO, we're here to listen and help you thrive."
},

{
  id: 3,
  image: IMG3,
  title: "AQS Solution",
  link: "https://aqssolution.000webhostapp.com/index.html",
  demo: "View Site",
  details: "AQS is a distinguished ISO certification company, and I had the privilege of designing their website. Committed to quality and compliance, AQS offers comprehensive ISO certification services to businesses across various industries. They excel in guiding organizations towards ISO standards, ensuring excellence in processes and services. Their user-friendly website provides valuable insights into ISO standards, certification processes, and compliance benefits. AQS empowers businesses to meet global quality standards, enhancing their competitive edge. My work on their website aimed to spotlight AQS's professionalism and commitment to ISO excellence, helping them connect with clients and advance their mission of quality assurance."
}
,
{
  id: 4,
  image: IMG4,
  title: "Billing Software",
  link: "https://github.com",
  demo: "GitHub",
  details: "The billing software I developed using Java GUI is a versatile and efficient tool designed for businesses of all sizes. It simplifies billing, offering a user-friendly interface for creating invoices, tracking payments, and managing financial transactions. Notable features include handling complex billing scenarios with tax calculations, discounts, and itemized billing, as well as providing comprehensive reports for informed financial decisions. Prioritizing data accuracy and security, it's a trustworthy choice for sensitive financial information. Its scalability adapts to businesses' evolving needs, from startups to enterprises. My goal with this project was to enhance operational efficiency and financial accuracy, ultimately contributing to businesses' sustained success and growth."
},

  {
    id: 5,
    image: IMG5,
    title: 'CryptoCall',
    link: 'https://github.com',
    demo: 'GitHub',
    details: 'Coming Soon!!! ',
  },
  {
    id: 6,
    image: IMG6,
    title: "Adography",
    link: "https://github.com",
    demo: "GitHub",
    details: "The Adography website, built with React.js and Spring Boot, is a harmonious blend of art and technology. It offers a captivating and user-friendly experience, showcasing the beauty of photography while demonstrating the capabilities of modern web development. Through this project, I aimed to create a platform for photographers and art enthusiasts to connect and appreciate visual storytelling. The website's elegant design and seamless performance invite visitors to explore the world of photography, making it a unique and engaging online destination."
}

];

const Portfolio = () => {
  // const [content, setContent] = useState(false);

  // const toggleContent = () => {
  //   setContent(!content);
  // };
  const [content, setContent] = useState(null);

  const openDetails = (item) => {
    setContent(item);
    document.body.classList.add('popup-open');
    console.log("open");
  };

  const closeDetails = () => {
    console.log("Closing details");
    setContent(null);
    document.body.classList.remove('popup-open');
  };
  const toggleContent = () => {
      setContent(!content);
      document.body.classList.remove('popup-open');
     };
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio_container'>
        {data.map((item) => {
          return (
            <article key={item.id} className='portfolio_item'>
              <div className='portfolio_item-image'>
                <img src={item.image} alt='image' />
              </div>
              <h3>{item.title}</h3>
              <div className='portfolio_item-cta'>
                <a href={item.link} className='btn' target='_blank' rel='noopener noreferrer'>
                  {item.demo}
                </a>
                <button onClick={()=>openDetails(item)} className='btn btn-primary'>
                  Details
                </button>
                {content && (
                  <div className='content'>
                    <div className='overlay' onClick={toggleContent}></div>
                    <div className='modal-content'>
                      <h2>{content.title}</h2>
                      <p>{content.details}</p>
                      <button className='close-modal' onClick={toggleContent}>
                        X
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
