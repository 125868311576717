import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {BiLogoLinkedin} from 'react-icons/bi'
const Footer = () => {
  return (
  <footer>
    <a href="#" className='footer_logo'>APPLIZR</a>
    <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#testimonials">Testimonials</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
    {/* <div className="footer_socials">
      <a href="https://www.facebook.com/dhriti.sinha.547/"><FaFacebookF/></a>
      <a href="https://www.instagram.com/__d.s.__/"><BsInstagram/></a>
      <a href="https://www.linkedin.com/in/dhriti-sinha-14b7811ab/"><BiLogoLinkedin/></a>
      
    </div> */}
    <div className="footer_copyright">
      <small>&copy; Applizr. All rights reserved.</small><br/>
      {/* <small>Designed & Developed By Dhriti Sinha.</small> */}
    </div>
  </footer>
  )
}

export default Footer
