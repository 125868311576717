import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
  <h5>What Skills Our Team Have</h5>
  <h2>Team's Experience</h2>
  <div className="container experience_container">
    <div className="experience_frontend">
      <h3>Frontend Development</h3>
      <div className="experience_content">
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>HTML</h4>
        <small className='text-light'>Experienced</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>CSS</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>Javascipt</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>Bootstrap</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>ReactJS</h4>
        <small className='text-light'>Beginner</small>
        
        </div>
        </article>
        
      </div>

    </div>
    <div className="experience_backend">
    <h3>Backend Development</h3>
      <div className="experience_content">
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>JAVA</h4>
        <small className='text-light'>Experienced</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>Spring Boot</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>Python/Django</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>Node JS</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>DB(SQL,NOSQL)</h4>
        <small className='text-light'>Intermediate</small>
        </div>
        </article>
        <article className='experience_details'>
        <BsPatchCheckFill className='experience_details-icon'/>
        <div>
        <h4>C/C++</h4>
        <small className='text-light'>Experienced</small>
        </div>
        </article>
        
      </div>
    </div>
  </div>
  </section>
  )
}

export default Experience
