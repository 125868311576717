import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
const Services = () => {
  return (
    <section id='services'>
     <h5>What I Offer</h5>
     <h2>Services</h2>
     <div className="container services_container">
      <article className='service'>
         <div className="service_head">
          <h3>UI/UX Design</h3>
         </div>
         <ul className='service_list'>
        <li><BiCheck className='service_list-icon'/>
        <p>User Interface (UI) Design</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>User Experience (UX) Design</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Wireframing and Prototyping</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Usability Testing</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Information Architecture</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Mobile App Design</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Web Application Design</p></li>
         </ul>
      </article>
      <article className='service'>
         <div className="service_head">
          <h3>Web Development</h3>
         </div>
         <ul className='service_list'>
        <li><BiCheck className='service_list-icon'/>
        <p>FrontEnd Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>BackEnd Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>FullStack Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>E-commerce Website Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Responsive Web Design</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Custom Web Application Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Content Management System (CMS) Development</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>API Development</p></li>
         </ul>
      </article>
      <article className='service'>
         <div className="service_head">
          <h3>Software Development</h3>
         </div>
         <ul className='service_list'>
        <li><BiCheck className='service_list-icon'/>
        <p>Learning Management Systems (LMS)</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Media Streaming Apps</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Desktop Applications</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Gaming Applications</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Management Systems</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Billing Software</p></li>
        <li><BiCheck className='service_list-icon'/>
        <p>Electronic Health Records (EHR)</p></li>
         </ul>
      </article>


     </div>

    </section>
  )
}

export default Services
