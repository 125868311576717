import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/me.jpeg';
import HeaderSocials from './HeaderSocials';
import LOGO1 from '../../assets/logo1.png';
import LOGO2 from '../../assets/logo4.png'

const Header = () => {
  return (
    <div>
      <header>
        <div className="container header_container">
          <div className="logo-container">
            <img src={LOGO1} alt="Logo" className="logo" />
          </div>
          <div className="header-content">
            <h5>Hello I am</h5>
           <img src={LOGO2} style={{maxHeight:"10rem",width:"100%"}} id='logo2' className='logo-flip'/>
            <h5 className="text-light" style={{ margin: 0 }}>Tech Startup</h5>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
